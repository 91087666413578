<template>
<v-app>
  <v-row>
      <v-col md="12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Chat</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \   Chat
                </div>
              </div>
              <div class="breadcrumb-right" >
                <div class="card-toolbar">
                  <v-btn  @click="createConversation()"  class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex flex-column flex-lg-row">
              <!--begin::Sidebar-->
              <div class="flex-column flex-lg-row-auto w-100 w-lg-300px w-xl-400px mb-10 mb-lg-0">
                <!--begin::Contacts-->
                <div class="card card-flush">
                  <div class="card-header pt-7" id="kt_chat_contacts_header">
                    <form class="w-100 position-relative" autocomplete="off">
<!--                      <v-autocomplete prepend-inner-icon="mdi-magnify" :items="users" outlined dense class="form-control-solid" :search-input.sync="userSearch" v-model="new_conversation.user_id" :filter="() => true"  item-text="display_text"   item-value="id">
                      </v-autocomplete>-->
                    </form>
                  </div>
                  <!--end::Card header-->
                  <!--begin::Card body-->
                  <div class="card-body pt-5" id="kt_chat_contacts_body">

                    <!--begin::List-->
                    <div class="scroll-y me-n5 pe-5 h-200px h-lg-auto" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto"
                         data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header" data-kt-scroll-wrappers="#kt_content, #kt_chat_contacts_body" data-kt-scroll-offset="5px">
                      <div  class="d-flex flex-stack py-4" v-show="conversations.length > 0" v-for="(conversation,index) in conversations" :key="index"  :class="{'chat_message_background_color':!conversation.last_message_seen,'':conversation.last_message_seen}">
                        <!--begin::Details-->
                        <div class="d-flex align-items-center" @click="openChatConversation(conversation)">
                          <!--begin::Avatar-->
                          <div class="symbol symbol-45px symbol-circle" @click="openChatConversation(conversation)">

                            <span  class="symbol symbol-35 symbol-light-success fs-6 fw-bolder" v-bind:class="{'symbol-light-success': index % 2 === 0, 'symbol-light-danger': index % 2 !== 0 }">
                              <span class="symbol-label font-size-sm fs-6 font-weight-bold cursor-pointer">
                                {{conversation.conversation_title ? conversation.conversation_title.charAt(0).toUpperCase() : '' }}
                              </span>
                            </span>
                            <div class="symbol-badge bg-success start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"></div>
                          </div>
                          <!--end::Avatar-->
                          <!--begin::Details-->
                          <div class="ms-5" @click="openChatConversation(conversation)">
                            <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary mb-2"> {{ conversation.conversation_title }}</a>
                            <div class="fw-semibold text-muted">
                              <p v-if="conversation.last_message_body">
                                {{conversation.last_message_body | truncate(300, '...')}}
                              </p>
                            </div>
                          </div>
                          <!--end::Details-->
                        </div>
                        <!--end::Details-->
                        <!--begin::Lat seen-->
                        <div class="d-flex flex-column align-items-end ms-2" @click="openChatConversation(conversation)">
                          <span class="text-muted fs-7 mb-1">{{conversation.last_message_sent_at}}</span>
                          <span style="color: red" class="badge badge-sm badge-circle badge-light-danger font-weight-bolder" v-if="conversation.unread_message_count > 0">{{conversation.unread_message_count}}</span>
                        </div>
                        <!--end::Lat seen-->
                      </div>
                      <div class="d-flex flex-stack py-4" v-if="conversations.length == 0">
                        <div class="d-flex align-items-center">
                          <div class="ms-5" >

                            <div class="fw-semibold ">
                              <p >
                                No conversation started.
                              </p>
                            </div>
                          </div>

                        </div>

                      </div>
                      <div class="separator separator-dashed d-none"></div>

                    </div>
                    <!--end::List-->
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Contacts-->
              </div>
              <!--end::Sidebar-->
              <!--begin::Content-->
              <div class="flex-lg-row-fluid ms-lg-7 ms-xl-10">
                <!--begin::Messenger-->
                <div class="card" id="kt_chat_messenger" v-if="conversationDetail">
                  <!--begin::Card header-->
                  <div class="card-header" id="kt_chat_messenger_header">
                    <!--begin::Title-->
                    <div class="card-title pt-3 px-3 d-flex justify-content-between">
                      <div class="breadcrumb-left">
                        <div class="d-flex justify-content-center flex-column me-3">
                          <a href="#" class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1">{{conversationDetail.conversation_title}}</a>
                          <!--begin::Info-->
                          <div class="mb-0 lh-1">
                            <span class="badge badge-success badge-circle w-10px h-10px me-1"></span>
                            <span class="fs-7 fw-semibold text-muted">Active</span>
                          </div>
                          <!--end::Info-->
                        </div>
                      </div>
                      <div class="breadcrumb-right">
                        <div class="card-toolbar">
                          <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon mt-4" no-caret right no-flip>
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item" @click="deleteConversation()" v-if="conversationDetail.conversation_admin==currentUser.id">
                                <a   class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-trash"></i>
                                  </span>
                                  <span class="navi-text">Delete conversation</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" @click="clearConversation()" >
                                <a   class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-close"></i>
                                  </span>
                                  <span class="navi-text">Clear conversation</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" @click="addParticipant()">
                                <a   class="navi-link">
                                  <span class="navi-icon">
                                      <i class="fa fa-user"></i>
                                  </span>
                                  <span class="navi-text">Add participant</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </div>
                      </div>
                    </div>


                  </div>
                  <!--end::Card header-->
                  <!--begin::Card body-->
                  <div class="card-body" id="kt_chat_messenger_body">
                    <div class="scroll-y me-n5 pe-5 h-300px h-lg-auto" data-kt-element="messages"  data-kt-scroll-max-height="auto"  data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body" data-kt-scroll-offset="5px">
                      <!--begin::Message(in)-->
                      <div class="d-flex  mb-10" v-bind:class="{'justify-content-start':!chat.is_sender,'justify-content-end':chat.is_sender}" v-show="chats.length > 0" v-for="(chat,index) in chats" :key="index">
                        <!--begin::Wrapper-->
                        <div class="d-flex flex-column align-items-start" v-if="!chat.is_sender">
                          <!--begin::User-->
                          <div class="d-flex align-items-center mb-2">
                            <!--begin::Avatar-->
                            <div class="symbol symbol-35px symbol-circle">
                              <img v-if="chat.sender_image_path && chat.sender_image_path.real"
                                   :src="chat.sender_image_path.real"
                                   class="cursor-pointer" alt=""

                                   style="height: 30px;">
                              <span v-else class="symbol symbol-35 symbol-light-success">
                                  <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                              {{chat.sender_first_name ? chat.sender_first_name.charAt(0).toUpperCase() : '' }}
                              {{ chat.sender_last_name ? chat.sender_last_name.charAt(0).toUpperCase() : '' }}
                            </span>
                                </span>
                            </div>
                            <!--end::Avatar-->
                            <!--begin::Details-->
                            <div class="ms-3">
                              <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{{chat.sender_full_name}}</a>
                              <span class="text-muted fs-7 mb-1">{{chat.message_sent_at}}</span>
                            </div>
                            <!--end::Details-->
                          </div>
                          <!--end::User-->
                          <!--begin::Text-->
                          <div class="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start" data-kt-element="message-text">
                            <p v-html="chat.body"></p>
                          </div>
                          <!--end::Text-->
                        </div>

                        <div class="d-flex justify-content-end mb-10" v-if="chat.is_sender">
                          <!--begin::Wrapper-->
                          <div class="d-flex flex-column align-items-end">
                            <!--begin::User-->
                            <div class="d-flex align-items-center mb-2">
                              <!--begin::Details-->
                              <div class="me-3">
                                <span class="text-muted fs-7 mb-1">{{chat.message_sent_at}}</span>
                                <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                              </div>
                              <!--end::Details-->
                              <!--begin::Avatar-->
                              <div class="symbol symbol-35px symbol-circle">
                                <img v-if="chat.sender_image_path && chat.sender_image_path.real"
                                     :src="chat.sender_image_path.real"
                                     class="cursor-pointer" alt=""

                                     style="height: 30px;">
                                <span v-else class="symbol symbol-35 symbol-light-success">
                                  <span class="symbol-label font-size-sm font-weight-bold cursor-pointer">
                                    {{chat.sender_first_name ? chat.sender_first_name.charAt(0).toUpperCase() : '' }}
                                    {{ chat.sender_last_name ? chat.sender_last_name.charAt(0).toUpperCase() : '' }}
                                  </span>
                                </span>
                              </div>
                              <!--end::Avatar-->
                            </div>
                            <!--end::User-->
                            <!--begin::Text-->
                            <div class="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end" data-kt-element="message-text">{{chat.body}}</div>
                            <!--end::Text-->
                          </div>
                          <!--end::Wrapper-->
                        </div>
                      </div>
                      <!--end::Message(in)-->
                      <!--begin::Message(out)-->


                    </div>

                  </div>
                  <!--end::Card body-->
                  <!--begin::Card footer-->
                  <div class="card-footer pt-4">
                    <!--begin::Input-->
                    <v-textarea outlined dense placeholder="Type a message" v-model="newMessage" :error="$v.newMessage.$error"></v-textarea>


                    <!--end::Input-->
                    <!--begin:Toolbar-->
                    <div class="d-flex flex-stack">
                      <!--begin::Actions-->
                      <div class="d-flex align-items-center me-2">
                        <button class="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                          <i class="bi bi-paperclip fs-3"></i>
                        </button>
                        <button class="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" title="Coming soon">
                          <i class="bi bi-upload fs-3"></i>
                        </button>
                      </div>
                      <!--end::Actions-->
                      <!--begin::Send-->
                      <v-row>
                        <v-col md="12" class="text-right">
                          <button class="btn btn-primary text-right" @click="sendMessage()"   v-on:keyup.enter="sendMessage()" data-kt-element="send">Send</button>
                        </v-col>

                      </v-row>


                    </div>
                    <!--end::Toolbar-->
                  </div>
                  <!--end::Card footer-->
                </div>
                <!--end::Messenger-->
              </div>
              <!--end::Content-->
            </div>
          </div>
        </div>
        <add-participant v-if="conversationDetail" ref="add-participant" :conversation_admin="conversationDetail.conversation_admin"></add-participant>
      </v-col>
    </v-row>

</v-app>
</template>
<script>
import {required, sameAs, minLength, numeric} from "vuelidate/lib/validators";
import ChatService from "@/core/services/chat/ChatService";
import AddParticipant from "./AddParticipant.vue";
const chat=new ChatService;
export default {
  components:{
    AddParticipant,
  },
  data(){
    return{
      chats:[],
      conversations:[],
      newMessage:null,
      conversationDetail:null,
    }
  },
  validations: {
    newMessage:{required},
  },
  methods:{
    getAllCurrentUserConversation(){
      chat
          .getAllConversations()
          .then(response=>{
            this.conversations=response.data.conversations;
          })
          .catch((err) => {

          })
          .finally(() =>{

          });
    },
    openChatConversation(item){
      this.conversationDetail=item;
      if(this.conversationDetail){
        this.getAllChatByConversationId();
        this.markWholeConversationAsRead();

      }
    },
    getAllChatByConversationId(){
      let data={};
      data.conversation_id=this.conversationDetail.id;
      chat
          .paginate(data)
          .then(response=>{
            this.chats=response.data.data;
          })
          .catch((err) => {

          })
          .finally(() =>{

          });
    },
    markWholeConversationAsRead(){
      chat
          .markWholeConversationAsRead(this.conversationDetail.id)
          .then((response) => {
            this.getAllCurrentUserConversation();
          })
          .catch((err) => { })
          .finally(() => {

          });
    },
    createConversation(){

    },

    sendMessage(){
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        let data = {};
        data.body = this.newMessage;
        data.conversation_id = this.conversationDetail.id;
        chat
            .store(data)
            .then(response => {
              this.getAllChatByConversationId();
              this.newMessage = null;
              this.$v.$reset();
            })
            .catch((err) => {

            })
            .finally(() => {

            });
      }
    },
    addParticipant(){
      this.$refs['add-participant'].addParticipant(this.conversationDetail.id);
    },
    clearConversation(){

    },
    deleteConversation(){

    },
  },
  mounted() {
    this.getAllCurrentUserConversation();
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>