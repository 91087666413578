import Apiservice from '../api.service';
import { API_URL } from '../../../common/config.js'
const apiService = Apiservice;

export default class ChatService
{
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/chat`;
    }
    paginate(data=null, index=1) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }
    getAllConversations(data={}){
        let url = `${this.#api}/get/all/conversations`;

        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
    markWholeConversationAsRead(conversationId){
        let url = `${this.#api}/${conversationId}/mark-as-read`;
        return apiService.get(url);
    }
    getAllConversationParticipationUser(conversationId){
        let url = `${this.#api}/${conversationId}/get/participation/user`
        return apiService.get(url);
    }
    addParticipationUserFromConversation(conversationId,data)
    {
        let url = `${this.#api}/${conversationId}/add-participation-user`
        return apiService.post(url,data);
    }
}
